// extracted by mini-css-extract-plugin
export var ArtistDescription = "Kase2-module--ArtistDescription--FhvrE";
export var ArtistInfos = "Kase2-module--ArtistInfos--Xy0cy";
export var ButtonWrapper = "Kase2-module--ButtonWrapper --kVo50";
export var CardWrapper = "Kase2-module--CardWrapper--FAcGi";
export var CarrouselWrapper2 = "Kase2-module--CarrouselWrapper2--fp+r6";
export var Citations = "Kase2-module--Citations--AF+AG";
export var DescriptionWrapper = "Kase2-module--DescriptionWrapper--ZDCIw";
export var ImageWrapper = "Kase2-module--ImageWrapper--Hb3HI";
export var LinkWrapper = "Kase2-module--LinkWrapper--vwTpv";
export var MobileProtrait = "Kase2-module--MobileProtrait--y1Cid";
export var More = "Kase2-module--More--wtynY";
export var MoreButton = "Kase2-module--MoreButton--4wi1j";
export var NameWrapper = "Kase2-module--NameWrapper--9tBA0";
export var PdpWrapper = "Kase2-module--PdpWrapper--3K-7c";
export var PhotosWrapper = "Kase2-module--PhotosWrapper--ioGxz";
export var ProfilWrapper = "Kase2-module--ProfilWrapper--o4vQ8";
export var TitleWrapper = "Kase2-module--TitleWrapper--4b4bQ";
export var Wrapper = "Kase2-module--Wrapper--6YNqV";