import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Kase2.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Kase2Photo1 from "../../../../res/Photos site/Kase 2/kase2 graff.jpg"
import Kase2Photo2 from "../../../../res/Photos site/Kase 2/KASE2_RIP.jpg"
import Kase2Photo3 from "../../../../res/Photos site/Kase 2/kase2-.webp"
import Kase2Photo4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kase 2 - Untitled - 1995.jpeg"
import CarrouselCartel from "../../../../components/CarrouselCartel";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Kase2",
  name: "Kase2",
  description:"",
  pdp: Kase2Photo3,
  alt_pdp: "Photo de profil de Kase2.",
  photos: [
    { src: Kase2Photo1, name: "Kase2" },
    { src: Kase2Photo2, name: "Kase2" },
    { src: Kase2Photo3, name: "Kase2" },
  ],
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: Kase2Photo4, name: "Kase 2", artist:"Kase 2\n(Chris Ellis dit) (né en 1962)", title:"138th street, 2020", desc1: "Peinture aérosol sur toile", desc2 :"Spray paint on canvas", desc3: "132 x 132 cm", desc4:" 51 5/8 x 51 5/8 in.", desc7: "- Acquis auprès de l'artiste." },
  ],
};

const Kase2 = () => {
    return (
        <div className={Wrapper}>
        <div className={CardWrapper}>
          <div className={ProfilWrapper} id="f">
            <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
          </div>
          <div>
            <h1 className={NameWrapper}>Kase 2</h1>
            <h3 className={NameWrapper} style={{paddingTop: '0'}}></h3>
            <p className={DescriptionWrapper}>{infos.description}</p>
          </div>
        </div>

        <div className={PhotosWrapper}>
          <Title title={"Past or Present"} />
          <div className={CarrouselWrapper2}>
          </div>
        </div>
      </div>
      );
};

export default Kase2;